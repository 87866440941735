import Vue from 'vue';
import VueScrollTo from "vue-scrollto";
import VueRouter from 'vue-router';
import {store} from "@/main";
import {VUEX_BUYOUT} from "js-mp/src/vue/store/buyout";
import {MUTATION_TYPES} from "__store__/consoleBuyout/_mutation-types";
import {ACTION_TYPES} from "__store__/consoleBuyout/_action-types";
import {sleep} from "js-mp/src/js/buyout";
import {VUEX_CONSOLE as CONSOLE_BUYOUT} from "__store__/consoleBuyout";

Vue.use(VueRouter);

const routes = [
	{
		path: '/ochrana-osobnich-udaju',
		name: 'privacyPolicy',
		component: () => import('../views/PrivacyPolicy.vue')
	},
	{
		path: '/pravni-informace',
		name: 'legalInformation',
		component: () => import('../views/LegalInformation.vue')
	},
	{
		path: '/podpora',
		name: 'Support',
		component: () => import('../views/Support.vue')
	},
	{
		path: '/partneri',
		name: 'Partners',
		component: () => import('../views/Partners.vue')
	},
	{
		path: '/svoz-uspech',
		name: 'CollectionSuccess',
		component: () => import('../views/BuyoutState.vue'),
		props: {
			isSuccess: true,
			mainTextKey: 'buyout.BuyoutState.collection_success'
		}
	},
	{
		path: '/svoz-jiz-objednan',
		name: 'CollectionAlreadyOrdered',
		component: () => import('../views/BuyoutState.vue'),
		props: {
			isSuccess: true,
			mainTextKey: 'buyout.BuyoutState.collection_already_ordered'
		}
	},
	{
		path: '/svoz-chyba',
		name: 'CollectionError',
		component: () => import('../views/BuyoutState.vue'),
		props: {
			isSuccess: false,
			mainTextKey: 'buyout.BuyoutState.something_went_wrong'
		}
	},
	{
		path: '/preceneni-prijato',
		name: 'RevaluationSuccess',
		component: () => import('../views/BuyoutState.vue'),
		props: {
			isSuccess: true,
			mainTextKey: 'buyout.Revaluation.revaluation_success'
		}
	},
	{
		path: '/preceneni-odmitnuto',
		name: 'RevaluationDenied',
		component: () => import('../views/BuyoutState.vue'),
		props: {
			isSuccess: false,
			mainTextKey: 'buyout.Revaluation.revaluation_denied'
		}
	},
	{
		path: '/preceneni-uzavreno',
		name: 'RevaluationClosed',
		component: () => import('../views/BuyoutState.vue'),
		props: {
			isSuccess: false,
			mainTextKey: 'buyout.Revaluation.revaluation_closed'
		}
	},
  {
		path: '/vykup/:userBuyoutCode',
		name: 'buyout',
		component: () => import('../views/Home.vue'),
		beforeEnter: async (to, from, next) => {
      if (!to.params.userBuyoutCode) next('/');
      if (store.state.userBuyoutCode === to.params.userBuyoutCode)
        next();
      else {
        store.dispatch(
          `${VUEX_BUYOUT.NAMESPACE}/${ACTION_TYPES.LOAD_BUYOUT}`
        );
        store.commit(
          `${VUEX_BUYOUT.NAMESPACE}/${MUTATION_TYPES.SET_IS_BUYOUT_ONLINE}`,
          true
        );
        store.commit(
          `${VUEX_BUYOUT.NAMESPACE}/${MUTATION_TYPES.SET_IS_REGISTRATION}`,
          false
        );
        store.commit(
          `${VUEX_BUYOUT.NAMESPACE}/${MUTATION_TYPES.SET_IS_LOGGED_IN}`,
          true
        );
        store.commit(
          `${VUEX_BUYOUT.NAMESPACE}/${MUTATION_TYPES.SET_BUYOUT_LOAD_ERROR}`,
          null
        );
        store.commit(
          `${VUEX_BUYOUT.NAMESPACE}/${MUTATION_TYPES.SET_USER_BUYOUT_CODE}`,
          to.params.userBuyoutCode
        );
        store.dispatch(
          `${VUEX_BUYOUT.NAMESPACE}/${ACTION_TYPES.LOAD_STATE_TYPES}`
        );
        store.dispatch(
          `${CONSOLE_BUYOUT.NAMESPACE}/${ACTION_TYPES.LOAD_GAMING_ACCESSORY}`
        );
        store.dispatch(
          `${CONSOLE_BUYOUT.NAMESPACE}/${ACTION_TYPES.LOAD_GAMING_ACCESSORY_QUANTITIES}`,
          to.params.userBuyoutCode
        );
        Vue.nextTick(async () => {
          await sleep(200);
          VueScrollTo.scrollTo('#loginSummary');
        });
      }
      next();
    }
	},
	{
		path: '/',
		name: 'home',
		component: () => import('../views/Home.vue'),
	}
];

const router = new VueRouter({
	mode: 'history',
	routes
});

export default router;
