import { allowed_date_formats } from './config';

export const overrides = {
	cs: {
		console: {
			page_title: 'PlayStation | Čas na upgrade',
			the_right_time: 'Ten pravý čas',
			for_upgrade: 'na upgrade',
			do_you_desire_for_a_new_console_but_you_are_short_on_money:
				'Toužíš po nové konzoli, ale chybí Ti peníze?',
			we_will_buyout_your_old_sony_playstation_console_money_will_be_send_to_your_account_and_the_new_can_be_yours:
				'Starou konzoli PlayStation od Tebe vykoupíme,       peníze pošleme na účet a nová může být Tvoje!',
      playstation_action_claim_main_part: "Toužíš po nové konzoli? Právě teď si můžeš snížit její cenu <span class='noWrap'>prodejem své</span> staré konzole.",
      playstation_action_duration: "*Akce platí od 22. 2. do 22. 3. 2023",
      copyright_text:
				'Veškerý obsah, všechny herní tituly, ochranné známky a / nebo úpravy, ochranné známky, umělecká díla a související ilustrace jsou ochranné známky nebo materiály chráněné autorskými právy příslušných vlastníků. Všechna práva vyhrazena. ',
			sale_of_used_consoles: 'Prodej použitých konzolí',
			choose_console_model_accessories_or_game: 'Vyber model a počet ovladačů',
      receipt_subtitle: "Prodejní doklad k nové konzoli PlayStation 5",
      register_sn_of_new_xbox: "Registrace S/N nové konzole PlayStation 5",
      imei_subtitle: "",
      partners: "Partneři akce",
      controllers: "Ovladače navíc",
      controllers_quantity: "Počet ovladačů navíc",
      i_have_multiple_controllers: "Mám více než jeden ovladač",
			multiple_controllers_info: "Zadej počet ovladačů, které máš navíc. Upozorňujeme, že 1 ovladač je součástí balení konzole a jeho cena je již zahrnuta ve výkupní ceně Tvé staré konzole."
		},
		validators: {
			required: 'Toto pole je povinné.',
			is_number: 'Toto pole může obsahovat pouze číslice.',
			invalid_bank_account_number: 'Neplatné číslo účtu.',
			invalid_bank_code: 'Neplatný kód banky.',
			min_allowed_value: 'Nejnižší povolená hodnota je: {minValue}.',
			invalid_email: 'Neplatný email',
			valid_post_code: 'Hodnota musí být platné PSČ',
			is_newer_date_valid: 'Datum musí být pozdější než dnešní',
			is_date_valid:
				'Chybný formát data. Použijte prosím jeden z těchto formátů: ' +
				allowed_date_formats.join(', ')
		},
		buyout: {
      give_registration_number: 'Zadej číslo svojí registrace',
      find_price_of_device: 'Zjisti hodnotu svého zařízení',
      buyout_price: 'Tvoje výkupní cena',
			thankYou: {
				preSale: {
					title:
						'Informativní nacenění bylo odesláno na Tvou e-mailovou adresu pod číslem <b>{0}</b>.',
					text: 'Pokud budeš chtít výkup dokončit, zastav se na některou z našich prodejen.'
				}
			},
			partners: {
				where_you_can_buy:
					'U kterých můžeš zakoupit konzole a příslušenství značky PlayStation'
			},
      AdditionalInfoForm: {
        new_model_partner_sub_text: 'Zvol prodejce nové konzole PlayStation 5',
      },
		},
	},
	en: {
		console: {
			page_title: 'PlayStation | Time for an upgrade',
			the_right_time: 'The right time',
			for_upgrade: 'to upgrade',
			do_you_desire_for_a_new_console_but_you_are_short_on_money:
				'Do you feel it´s time for a new console, but you need more money?',
			we_will_buyout_your_old_sony_playstation_console_money_will_be_send_to_your_account_and_the_new_can_be_yours:
				'We will buy out the old PlayStation console from you, send the money to your bank account and the new console can be yours!',
			copyright_text:
				'All content, all game titles, trademarks and / or modifications, trademarks, artwork and related illustrations are trademarks or copyrighted materials of their respective owners. All rights reserved.',
			sale_of_used_consoles: 'Sale of used consoles',
			choose_console_model_accessories_or_game:
				'Select your console model and number of controllers'
		},
		validators: {
			required: 'This field is mandatory.',
			is_number: 'This field can only contain digits.',
			invalid_bank_account_number: 'Invalid account number.',
			invalid_bank_code: 'Invalid bank code.',
			min_allowed_value: 'The lowest value allowed is: {minValue}.',
			invalid_email: 'Invalid email',
			valid_post_code: 'Value must be a valid zip code',
			is_newer_date_valid: "Date must be later than today's date",
			is_date_valid:
				'Incorrect date format. Please use one of the following formats: ' +
				allowed_date_formats.join(', ')
		},
		buyout: {
			thankYou: {
				preSale: {
					title:
						'Informative pricing was sent to your e-mail address under the number <b>{0}</b>.',
					text: 'If you want to complete your purchase, stop by one of our stores.'
				},
			},
			partners: {
				where_you_can_buy:
					'Where you can buy PlayStation consoles and accessories',
			},
		},
	}
};
