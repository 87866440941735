<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'PlaystationApp',
  created(){
    window.document.title = this.$t('console.page_title');
  }
}
</script>
