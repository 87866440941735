import { buyout } from 'js-mp';
import extend from 'lodash/extend';
import router from '@/router';

const config_overrides = {
	currency: 'Kč',
	language: 'cs',
	gaming_brand: 'sony',
	category_seo_name: 'gaming',
	router: router,
  scrollToElements: false,
};
export const allowed_date_formats = [
	'DD.MM.YYYY',
	'DD.MM.YY',
	'DD. MM. YYYY',
	'DD. MM. YY',
	'YYYY-MM-DD'
];
buyout.config = extend(buyout.config, config_overrides);
export const config = buyout.config;
