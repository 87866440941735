import { ACTION_TYPES as CONSOLE_AC } from './_action-types';
import { MUTATION_TYPES as BUYOUT_MT } from 'js-mp/src/vue/store/buyout/_mutation-types';
import { MUTATION_TYPES as CONSOLE_MT } from '__store__/consoleBuyout/_mutation-types';
import { ACTION_TYPES as BUYOUT_AC } from 'js-mp/src/vue/store/buyout/_action-types';
import { URLS } from 'js-mp/src/js/buyout';
import { VUEX_BUYOUT } from 'js-mp/src/vue/store/buyout';
import { buyout } from 'js-mp';

export const actions = {
	async [CONSOLE_AC.LOAD_GAMING_ACCESSORY]({ commit, dispatch }) {
		const { response, error } = await dispatch(
			`${VUEX_BUYOUT.NAMESPACE}/${BUYOUT_AC.LOAD_DATA}`,
      {url: URLS.GAMING_ACCESSORY},
			{
				root: true
			}
		);
		if (error) return;

		let accessoriesData = response.data;
		commit(
			CONSOLE_MT.SET_CONTROLLER_DATA,
			accessoriesData.filter(accessories => {
				return accessories.device_type === 'gaming_controller';
			})[0]
		);
		commit(
			CONSOLE_MT.SET_GAME_DATA,
			accessoriesData.filter(accessories => {
				return accessories.device_type === 'gaming_game';
			})[0]
		);
	},
  async [CONSOLE_AC.LOAD_GAMING_ACCESSORY_QUANTITIES]({ dispatch, commit }, userBuyoutCode) {
		const { response, error } = await dispatch(
			`${VUEX_BUYOUT.NAMESPACE}/${BUYOUT_AC.LOAD_DATA}`,
      {url: `${URLS.GAMING_ACCESSORY_QUANTITIES}/${userBuyoutCode}`},
			{
				root: true
			}
		);
		if (error) return;

		let accessoriesData = response.data;
		let controller_data = accessoriesData.find(accessories => {
				return accessories.device_type === 'gaming_controller';
    })
    let games_data = accessoriesData.find(accessories => {
				return accessories.device_type === 'gaming_game';
    })
		commit(
			CONSOLE_MT.SET_CONTROLLERS_QUANTITY,
			controller_data && controller_data?.quantity ? controller_data.quantity : 0
		);
		commit(
			CONSOLE_MT.SET_GAMES_QUANTITY,
			games_data && games_data?.quantity ? games_data.quantity : 0
		);
	},
	async [CONSOLE_AC.LOAD_COLORS]({ commit, dispatch }) {
		commit(`${VUEX_BUYOUT.NAMESPACE}/${BUYOUT_MT.SET_LOADING_COLORS}`, true, {
			root: true
		});
		commit(
			`${VUEX_BUYOUT.NAMESPACE}/${BUYOUT_MT.RESET_COLORS}`,
			{},
			{ root: true }
		);
		dispatch(
			`${VUEX_BUYOUT.NAMESPACE}/${BUYOUT_AC.RESET_APPRAISAL}`,
			{},
			{
				root: true
			}
		);
		const { response, error } = await dispatch(
			`${VUEX_BUYOUT.NAMESPACE}/${BUYOUT_AC.LOAD_DATA}`,
      {url: `${URLS.PRODUCT_COLORS}/${buyout.store.state.selectedModel.seo_name}`
  },
			{ root: true }
		);
		if (error) return;

		commit(`${VUEX_BUYOUT.NAMESPACE}/${BUYOUT_MT.SET_COLORS}`, response.data, {
			root: true
		});
		commit(
			`${VUEX_BUYOUT.NAMESPACE}/${BUYOUT_MT.SET_LOADING_COLORS}`,
			!buyout.store.state.loadingColors,
			{ root: true }
		);
		commit(
			`${VUEX_BUYOUT.NAMESPACE}/${BUYOUT_MT.SET_SELECTED_COLOR}`,
			response.data[0],
			{ root: true }
		);
		commit(`${VUEX_BUYOUT.NAMESPACE}/${BUYOUT_MT.SET_LOADING_COLORS}`, false, {
			root: true
		});
	}
};
